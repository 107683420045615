export const isValidID = (id: string): boolean => {
  if (!id) {
    return false;
  }
  const idLowerCase = id.toLocaleLowerCase().trim();
  return (
    typeof idLowerCase === 'string' &&
    idLowerCase !== 'none' &&
    idLowerCase !== 'false' &&
    idLowerCase !== 'null' &&
    idLowerCase !== 'empty'
  );
};
