// TODO: add tracking
// TODO: understand this.handleUrlMask(); from the old project
import * as React from 'react';
import { useEffect } from 'react';
import { DatalayerItem, TrackingService } from '@rocheglobal/component-library-dot-com/src/services/tracking';
import { HeaderSection } from '../components/custom/header-section';
import { DomService } from '../services/dom';
import { getAlternates } from '../services/dom/util';
import { StoryData } from '../services/build/types';
import { blokToComponent } from '../helpers/blok-to-component';
import { getComponent } from '../helpers/get-component';
import { AdobeAnalytics } from '../components/custom/adobe-analytics';
import { Analytics } from '../components/custom/analytics';
import { Hotjar } from '../components/custom/hotjar';
import { MetaRobotsContent } from '../components/custom/meta-robots-content';
import { SEO } from '../components/custom/seo';
import { maskAssetUrl } from '../utils/mask-asset-url';
import { LanguageService } from '../services/language';
import { StringService } from '../services/string';
import { StoryblokService } from '../services/storyblok';
import { ScrollTracking } from '../components/custom/scroll-tracking';
import { PageTypes } from '../services/dom/types';
import { AdobeTracking } from '../tracking-services';
import { FontOverride } from '../components/custom/font-override';

const Breadcrumbs = getComponent('roche-breadcrumbs') as React.ElementType;
const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;

const DefaultTemplate: React.FC<StoryData> = ({ pageContext, pageResources, location }) => {
  const {
    story: { content: storyContent, alternates, id: storyId, lang, full_slug: fullSlug },
    home,
    navigation: { content: navigationContent },
    footer,
    breadcrumbs,
    fontOverwrites,
  } = pageContext;

  useEffect(() => {
    trackPageView();

    DomService.activateConsentScript();
    // TODO: test this
    window.addEventListener('rocheLoginComplete', () => StoryblokService.redirect());
  }, []);

  useEffect(() => {
    handle404();
  }, [pageContext]);

  const handle404 = () => {
    const is404Page = pageResources.page.path === '/404.html';

    if (!is404Page) {
      return;
    }

    const unreachableRouteLocale = LanguageService.getLocaleFromSlug(location.pathname);
    if (!alternates || alternates.length === 0) {
      return;
    }
    const localized404Page = alternates.find(({ locale }) => locale === unreachableRouteLocale);

    if (!localized404Page) {
      return;
    }

    window.setTimeout(() => {
      const sanitizedUrl = StringService.sanitizeUrl(`/${localized404Page.url}`);
      window.location.replace(sanitizedUrl);
    }, 10);
  };

  const {
    teaser_image: teaserImage,
    teaser_title: teaserTitle,
    teaser_description: teaserDescription,
    teaser_type: teaserType,
    hide_search_engines: hideSearchEngines,
    microsite_header: micrositeHeader,
    hide_breadcrumbs: hideBreadcrumbs,
    hide_footer: hideFooter,
    hide_stock_price: hideStockPrice,
  } = storyContent;

  const isStoryTypeContent = storyContent.component === 'story';
  const shouldShowBreadcrumbs = !hideBreadcrumbs && !isStoryTypeContent && !micrositeHeader;

  const trackPageView = () => {
    TrackingService.push({
      component_name: '',
      event: 'page_view',
      additionalParameters: {
        page_type: DomService.getPageType(pageContext.isHomePage, pageContext.story?.content.component) as PageTypes,
      },
    } as DatalayerItem);

    const waitForOptanonGroups = (callback: () => void) => {
      const checkOptanonGroups = setInterval(() => {
        if (window.OptanonActiveGroups !== undefined) {
          clearInterval(checkOptanonGroups);
          callback();
        }
      }, 100);
    };

    waitForOptanonGroups(() => {
      AdobeTracking(pageContext, 'defaultPageView');
    });
  };

  const handleConsentUpdate = () => {
    AdobeTracking(pageContext, 'consentUpdate');
  };

  useEffect(() => {
    window.addEventListener('onetrustInitialized', handleConsentUpdate);
    return () => window.removeEventListener('onetrustInitialized', handleConsentUpdate);
  }, []);

  const globalConfig = DomService.getGlobalConfig(pageContext.story);
  const disableIndexing = process.env.GATSBY_BLOCK_SE_INDEXING === 'true';
  const metaTags = Object.entries(storyContent.meta_tags ?? {}).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: maskAssetUrl(value as string),
    }),
    {},
  );

  return (
    <div>
      <FontOverride overrides={fontOverwrites} />
      <SEO
        {...metaTags}
        lang={lang}
        slug={fullSlug}
        contentType={teaserType !== undefined ? 'stories' : 'pages'}
        story_type={teaserType || 'none'}
        teaser_image={maskAssetUrl(teaserImage?.[0]?.src?.filename || '')}
        teaser_title={teaserTitle}
        teaser_description={teaserDescription}
        first_published_at={pageContext.story.first_published_at?.toString()}
      ></SEO>
      <ScrollTracking data={pageContext} />
      <Hotjar />
      <AdobeAnalytics />
      <Analytics />
      <RocheGlobalConfig {...globalConfig} />
      <HeaderSection
        home={home}
        storyContent={storyContent}
        navigationContent={navigationContent}
        alternates={getAlternates(alternates)}
      />
      {(disableIndexing || hideSearchEngines) && <MetaRobotsContent />}

      {shouldShowBreadcrumbs && <Breadcrumbs list={breadcrumbs} />}

      {blokToComponent({ getComponent, blok: storyContent, storyId })}

      {!hideFooter &&
        footer &&
        blokToComponent({
          getComponent,
          blok: {
            ...footer.content,
            canShowStockPrice: hideStockPrice,
          },
        })}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default DefaultTemplate;
export { Head } from '../components/custom/head';
