import React from 'react';
import { Helmet } from 'react-helmet';
import { StringService } from '../../services/string';
import { isValidID } from '../../helpers/datasources';

export const Analytics = (): JSX.Element => (
  <Helmet>
    <script type='text/plain' className='optanon-category-C0002'>
      {`
          !function() {
            var e = function(e, t, n) {
                if (n) {
                    var o = new Date;
                    o.setTime(o.getTime() + 24 * n * 60 * 60 * 1e3);
                    var a = "; expires=" + o.toGMTString()
                } else a = "";
                document.cookie = e + "=" + t + a + ";domain=.${StringService.removeProtocolAndSubdomains(
                  process.env.GATSBY_WEBSITE_DOMAIN,
                )};path=/"
            };
            var t = new Image;
            t.addEventListener("error", function() {
                e("netLocation", "external")
            }), t.addEventListener("load", function() {
                e("netLocation", "internal")
            })
            t.src = "https://digitalregistry.roche.com/favicon.ico"
          }();
        `}
    </script>
    {isValidID(process.env.GATSBY_ROCHE_GOOGLE_TAG_MANAGER_ID) && (
      <script type='text/plain' className='optanon-category-C0002'>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_ROCHE_GOOGLE_TAG_MANAGER_ID}');

          window.dataLayer = window.dataLayer || [];
        `}</script>
    )}
  </Helmet>
);
